import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import './SignUp.css';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Recaptcha from 'react-google-invisible-recaptcha';
import { env } from '../../components/app/features/env';
import {
   Box,
   Button,
   TextField,
   Typography,
   IconButton,
   InputAdornment,
   styled,
   CircularProgress,
} from '@mui/material';
import {
   createAccount,
   resetLoading,
} from '../../components/app/features/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSelector } from '../../components/utils/selectors/selectors';
// import GoogleIcon from '../../assets/GoogleIcons.svg';
// import NigeriaFlag from '../../assets/NigeriaFlag.svg';
// import { toast } from 'react-toastify';

const TextInput = styled(TextField)(
   ({ theme }) => `
     color: #49454F;
   `
);

// const SuspendButton = styled(Button)(
//    ({ theme }) => `
//   border-radius: 10px;
//   padding: 2px 5px;
//   font-size: 0.8125rem;
//   background: transparent;
//   border: 1px solid #374DA0;
//   color: #374DA0;
//   width: 45%;
//   margin: 0 auto;
//   margin-top: 15px;
//   whitespace: nowrap;

//   &:hover {
//   background: #F7F7F8;
//   }
//   `
// );

const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' };

const SignUp = () => {
   const dispatch = useDispatch();
   const selector = useSelector(getAuthSelector);
   const [showPassword, setShowPassword] = useState(false);
   const [showPasswordTwo, setShowPasswordTwo] = useState(false);
   const recaptchaRef = useRef(null);

   const navigate = useNavigate();

   useEffect(() => {
      // redirect user to account-verification page if registration was successful
      dispatch(resetLoading());
      if (selector.loading === 'success') {
         navigate('/account-verification');
      }
   }, [selector.loading]);

   useEffect(() => {
      // redirect user to account-verification page if registration was successful
      dispatch(resetLoading());
      if (selector.error === 400) {
         navigate(selector.action);
      }
   }, [selector.error]);

   const handleClickShowPassword = () => setShowPassword((show) => !show);
   const handleClickShowPasswordTwo = () => setShowPasswordTwo((show) => !show);

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };
   const handleCreateAccountSubmit = async (values, { setErrors }) => {
      if (recaptchaRef.current) {
         const recaptChaToken = await recaptchaRef.current.execute();
         if (recaptChaToken) {
            dispatch(createAccount(values));
         } else {
            setErrors({ submit: 'Please complete the reCAPTCHA' });
            recaptchaRef.current.reset();
         }
      }
   };
   const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' };
   <TextField name="last_name" inputProps={{ style: inputStyle }} />;

   return (
      <Box>
         <Box margin="auto" width="10%" display="flex" gap={0.5} mt={2}>
            <Box className="dot1"></Box>
            <Box className="dot1 dot2"></Box>
            <Box className="dot1 dot2"></Box>
         </Box>
         <Box
            fontFamily="'Raleway', 'Montserrat', sans-serif"
            fontWeight={600}
            className="pageHeader"
         >
            Create Account
         </Box>
         <Typography
            fontFamily="'Raleway', 'Montserrat', sans-serif"
            fontWeight={600}
            color="#02222d"
         >
            Let's create your account in 3 simple steps
         </Typography>
         <Formik
            initialValues={{
               firstName: '',
               lastName: '',
               email: '',
               // phoneNumber: '',
               password: '',
               confirmPassword: '',
            }}
            validationSchema={Yup.object().shape({
               firstName: Yup.string()
                  .max(255)
                  .required('The name field is required'),
               lastName: Yup.string()
                  .max(255)
                  .required('The name field is required'),
               email: Yup.string()
                  .email('The email provided should be a valid email address')
                  .max(255)
                  .required('The email field is required'),
               // phoneNumber: Yup.string()
               // 	.max(255)
               // 	.required('The phone number field is required'),
               password: Yup.string()
                  .max(255)
                  .required('Please enter your password'),
               confirmPassword: Yup.string()
                  .label('confirm password')
                  .required('Please confirm your password')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            })}
            onSubmit={handleCreateAccountSubmit}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
               setFieldValue,
            }) => (
               <form noValidate onSubmit={handleSubmit}>
                  <label
                     htmlFor="firstName"
                     style={{
                        textAlign: 'left',
                        display: 'block',
                        lineHeight: 1.5,
                     }}
                  >
                     Full name
                  </label>
                  <Box display="flex" gap={1} sx={{ marginBottom: '1rem' }}>
                     <TextInput
                        fullWidth
                        margin="normal"
                        name="firstName"
                        id="firstName"
                        placeholder="Firstname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.firstName}
                        variant="outlined"
                        InputProps={{
                           style: inputStyle,
                           startAdornment: (
                              <InputAdornment position="start">
                                 <PersonIcon background="#374DA0" />
                              </InputAdornment>
                           ),
                           endAdornment: values.firstName !== '' && (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => {
                                       setFieldValue('firstName', '');
                                    }}
                                 >
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                     <TextInput
                        fullWidth
                        margin="normal"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.lastName}
                        placeholder="Lastname"
                        variant="outlined"
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <PersonIcon background="#374DA0" />
                              </InputAdornment>
                           ),
                           endAdornment: values.lastName !== '' && (
                              <InputAdornment position="end">
                                 <IconButton
                                    edge="end"
                                    onClick={() => {
                                       setFieldValue('lastName', '');
                                    }}
                                 >
                                    <HighlightOffIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Box>
                  {/* <TextInput
							fullWidth
							margin='normal'
							// name='phoneNumber'
							placeholder='Phone number'
							onBlur={handleBlur}
							onChange={handleChange}
							type='text'
							value={values.phoneNumber}
							variant='outlined'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<img src={NigeriaFlag} alt='flag' className='nigeriaFlag' />
									</InputAdornment>
								),
								endAdornment: values.phoneNumber !== '' && (
									<InputAdornment position='end'>
										<IconButton
											edge='end'
											onClick={() => {
												setFieldValue('phoneNumber', '');
											}}>
											<HighlightOffIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/> */}
                  <label
                     htmlFor="email"
                     style={{
                        textAlign: 'left',
                        display: 'block',
                        lineHeight: 1.5,
                     }}
                  >
                     Email
                  </label>
                  <TextInput
                     fullWidth
                     margin="normal"
                     name="email"
                     id="email"
                     placeholder="Enter email address"
                     onBlur={handleBlur}
                     onChange={handleChange}
                     type="text"
                     value={values.email}
                     variant="outlined"
                     InputProps={{
                        style: {
                           marginBottom: '1rem',
                        },
                        startAdornment: (
                           <InputAdornment position="start">
                              <EmailIcon />
                           </InputAdornment>
                        ),
                        endAdornment: values.email !== '' && (
                           <InputAdornment position="end">
                              <IconButton
                                 edge="end"
                                 onClick={() => {
                                    setFieldValue('email', '');
                                 }}
                              >
                                 <HighlightOffIcon />
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
                  <label
                     htmlFor="password"
                     style={{
                        textAlign: 'left',
                        display: 'block',
                        lineHeight: 1.5,
                     }}
                  >
                     Password
                  </label>
                  <TextInput
                     error={Boolean(touched.password && errors.password)}
                     fullWidth
                     margin="normal"
                     helperText={touched.password && errors.password}
                     name="password"
                     id="password"
                     placeholder="Enter password"
                     onBlur={handleBlur}
                     onChange={handleChange}
                     type={showPassword ? 'text' : 'password'}
                     value={values.password}
                     variant="outlined"
                     InputProps={{
                        style: {
                           marginBottom: '1rem',
                        },
                        startAdornment: (
                           <InputAdornment position="start">
                              <LockIcon />
                           </InputAdornment>
                        ),
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword}
                                 onMouseDown={handleMouseDownPassword}
                                 edge="end"
                              >
                                 {showPassword ? (
                                    <VisibilityOff />
                                 ) : (
                                    <Visibility />
                                 )}
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
                  <label
                     htmlFor="confirmPassword"
                     style={{
                        textAlign: 'left',
                        display: 'block',
                        lineHeight: 1.5,
                     }}
                  >
                     Confirm password
                  </label>
                  <TextInput
                     fullWidth
                     margin="normal"
                     helperText={
                        touched.confirmPassword && errors.confirmPassword
                     }
                     error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                     )}
                     name="confirmPassword"
                     id="confirmPassword"
                     placeholder="Confirm password"
                     onBlur={handleBlur}
                     onChange={handleChange}
                     type={showPasswordTwo ? 'text' : 'password'}
                     value={values.confirmPassword}
                     variant="outlined"
                     InputProps={{
                        style: {
                           marginBottom: '1rem',
                        },
                        startAdornment: (
                           <InputAdornment position="start">
                              <LockIcon />
                           </InputAdornment>
                        ),
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPasswordTwo}
                                 onMouseDown={handleMouseDownPassword}
                                 edge="end"
                              >
                                 {showPasswordTwo ? (
                                    <VisibilityOff />
                                 ) : (
                                    <Visibility />
                                 )}
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
                  <Typography
                     fontSize={13}
                     fontFamily="'Raleway', 'Montserrat', sans-serif"
                     fontWeight={500}
                     color="#02222d"
                  >
                     By clicking next, you agree to Parrotica's
                     <a
                        href="https://parrotica.com/policy.html"
                        className="termsLink"
                     >
                        {' '}
                        Privacy Policy{' '}
                     </a>{' '}
                     and{' '}
                     <a
                        href="https://parrotica.com/terms.html"
                        className="termsLink"
                     >
                        {' '}
                        Legal Term of Use
                     </a>
                  </Typography>
                  <Button
                     sx={{
                        borderRadius: '11px',
                        py: '5px',
                        px: '35px',
                        fontSize: '1rem',
                        width: '45%',
                        mx: 'auto',
                        my: '1.5rem',
                     }}
                     variant="contained"
                     size="small"
                     type="submit"
                     startIcon={
                        selector.loading === 'pending' ? (
                           <CircularProgress size="1rem" color="primary" />
                        ) : null
                     }
                     disabled={
                        !values.firstName ||
                        !values.lastName ||
                        // !values.phoneNumber ||
                        !values.email ||
                        !values.password ||
                        !values.confirmPassword
                     }
                  >
                     Next
                  </Button>
                  <Typography>
                     Already have an account?{' '}
                     <Link to="/login" className="termsLink">
                        Log in
                     </Link>
                  </Typography>
                  <Recaptcha
                     ref={recaptchaRef}
                     sitekey={env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
               </form>
            )}
         </Formik>
      </Box>
   );
};

export default SignUp;
