import {
   getBaseUrls,
   userLSAuth
} from '../env.js';
import httpclient from '../httpClient.js';
import {
   createAsyncThunk,
   createSlice
} from '@reduxjs/toolkit';
import setAuthToken from '../../../utils/setAuthToken.js';
import {
   toast
} from 'react-toastify';

const initialState = {
   contacts: [],
   groups: [],
   loading: 'idle',
   admin: userLSAuth(),
   totalContacts: 0,
   created: false,
   createdContactsCount: 0,
   user: [],
   group: [],
   starred: false,
   summary: [],
   pricing: [],
   vcf: [],
   error: null,
   added: false,
   groupCosts: [],
   paginate: 0,
   hasMore: true,
   responseMessage: ''
};

// get contacts
export const contactSummary = createAsyncThunk(
   'get/summary',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/contact-summary/${userLSAuth().id}`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

// get contacts
export const fetchContacts = createAsyncThunk(
   'get/contacts',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/contacts/${userLSAuth().id}?page=${
               payload.page
            }&take=500`
         );
         if (response.status && response.status === 200) {
            return response.data;
         }
         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

// get single User
export const getUser = createAsyncThunk(
   'get/user',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/contacts/${userLSAuth().id}/${
               payload.contactId
            }`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

// create contact
export const createContact = createAsyncThunk(
   'create/contact',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/contacts/${
               payload.userId ? payload.userId : userLSAuth().id
            }?groupId=${payload.groupId}`,
            payload.contact,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// open contact vcf
export const openVcf = createAsyncThunk(
   'open/contactVcf',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         var ext = payload.name.substr(payload.name.lastIndexOf('.') + 1);
         const config = {
            headers: {
               'Content-Type': 'multipart/form-data',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/upload-${ext.toLowerCase()}`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// edit contact
export const editContact = createAsyncThunk(
   'edit/contact',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.put(
            `${getBaseUrls().data}/v1/contacts/${
               payload.userId ? payload.userId : userLSAuth().id
            }/${payload.contactId}`,

            {
               fullName: payload.name,
               number: payload.phone,
            },

            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// delete multiple contact
export const deleteMultipleUsers = createAsyncThunk(
   'delete/users',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.delete(
            `${getBaseUrls().data}/v1/contacts/${
               payload.userId ? payload.userId : userLSAuth().id
            }?ids=${payload.users}&deleteAll=false`,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// delete contact
export const deleteContact = createAsyncThunk(
   'delete/contact',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.delete(
            `${getBaseUrls().data}/v1/contacts/${
               payload.userId ? payload.userId : userLSAuth().id
            }/${payload.contactId}`
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const contactPricingInfo = createAsyncThunk(
   'pricing/contact',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/contacts-cost/${
               payload.userId ? payload.userId : userLSAuth().id
            }`
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const createGroup = createAsyncThunk(
   'create/group',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/groups/${
               payload.userId ? payload.userId : userLSAuth().id
            }`,

            {
               title: payload.name,
               description: payload.desc,
            },

            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const fetchGroups = createAsyncThunk(
   'get/Groups',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/groups/${userLSAuth().id}?page=${
               payload.page
            }&take=500`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const fetchSingleGroup = createAsyncThunk(
   'get/group',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/groups/${
               payload.userId ? payload.userId : userLSAuth().id
            }/${payload.groupId}`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const editGroup = createAsyncThunk(
   'edit/group',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.put(
            `${getBaseUrls().data}/v1/groups/${payload.groupId}`,

            {
               title: payload.name,
               description: payload.desc,
            },

            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const addMultipleUsersToGroup = createAsyncThunk(
   'add/users',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.patch(
            `${getBaseUrls().data}/v1/${
               payload.userId ? payload.userId : userLSAuth().id
            }/groups/${payload.groupId}`,
            payload.contacts,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const starGroup = createAsyncThunk(
   'star/group',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/group-star/${payload.groupId}?star=${
               payload.starred
            }`,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const deleteContactFromGroup = createAsyncThunk(
   'deletecontact/group',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.delete(
            `${getBaseUrls().data}/v1/groups/${payload.groupId}?ids=${
               payload.users
            }`
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.info('contact deleted sucessfully');
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const deleteGroup = createAsyncThunk(
   'delete/group',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.delete(
            `${getBaseUrls().data}/v1/groups/${payload.groupId}`
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.info('contact deleted sucessfully');
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const groupPricingInfo = createAsyncThunk(
   'groupPricing/contact',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/group-cost/${payload}`
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

const contactSlice = createSlice({
   name: 'contact',
   initialState,
   reducers: {
      resetCreated: (state, action) => {
         state.created = false;
      },
   },
   extraReducers: (builder) => {
      builder

         .addCase(contactSummary.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(contactSummary.fulfilled, (state, action) => {
            state.loading = 'success';
            state.summary = action.payload.data;
         })
         .addCase(contactSummary.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(fetchContacts.pending, (state, action) => {
            state.loading = 'pending';
            state.fetched = false;
         })
         .addCase(fetchContacts.fulfilled, (state, action) => {
            if (state.totalContacts !== action.payload.meta.total) {
               state.totalContacts = action.payload.meta.total;
            }
            if (
               state.contacts.length >= state.totalContacts ||
               action.payload.data.length === 0 ||
               state.totalContacts === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               const newData = action.payload.data.filter(
                  (item) =>
                  !state.contacts.some((contact) => contact.id === item.id)
               );
               state.contacts = [...state.contacts, ...newData];
               if (state.contacts.length === state.totalContacts) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(fetchContacts.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
            state.fetched = false;
         })
         .addCase(createContact.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(createContact.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
            state.createdContactsCount = action.payload.data.length;
            state.responseMessage = action.payload.meta[0].message;
         })
         .addCase(createContact.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(openVcf.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(openVcf.fulfilled, (state, action) => {
            state.loading = 'success';
            state.vcf = action.payload.data;
            state.added = true;
         })
         .addCase(openVcf.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getUser.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getUser.fulfilled, (state, action) => {
            state.loading = 'success';
            state.user = action.payload.data;
         })
         .addCase(getUser.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(editContact.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(editContact.fulfilled, (state, action) => {
            state.loading = 'success';
            state.user = action.payload.data;
         })
         .addCase(editContact.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(deleteContact.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(deleteContact.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(deleteContact.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(deleteMultipleUsers.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(deleteMultipleUsers.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(deleteMultipleUsers.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(createGroup.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(createGroup.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
         })
         .addCase(createGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(fetchGroups.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(fetchGroups.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;
            if (
               state.groups.length === state.paginate ||
               state.paginate === 0 ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               const filteredData = newData.filter(
                  (item) => !state.groups.some((data) => data.id === item.id)
               );
               state.groups = [...state.groups, ...filteredData];
               if (state.groups.length === state.paginate) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(fetchGroups.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(fetchSingleGroup.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(fetchSingleGroup.fulfilled, (state, action) => {
            state.loading = 'success';
            state.group = action.payload.data;
         })
         .addCase(fetchSingleGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(editGroup.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(editGroup.fulfilled, (state, action) => {
            state.loading = 'success';
            state.group = action.payload.data;
            state.created = true;
         })
         .addCase(editGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(addMultipleUsersToGroup.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(addMultipleUsersToGroup.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
         })
         .addCase(addMultipleUsersToGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(starGroup.pending, (state, action) => {
            state.loading = 'pending';
            state.starred = false;
         })
         .addCase(starGroup.fulfilled, (state, action) => {
            state.loading = 'success';
            state.starred = true;
         })
         .addCase(starGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
            state.starred = true;
         })
         .addCase(deleteContactFromGroup.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(deleteContactFromGroup.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(deleteContactFromGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(deleteGroup.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(deleteGroup.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(deleteGroup.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(contactPricingInfo.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(contactPricingInfo.fulfilled, (state, action) => {
            state.loading = 'success';
            state.pricing = action.payload.data;
         })
         .addCase(contactPricingInfo.rejected, (state, action) => {
            state.loading = 'failed';
         })
         .addCase(groupPricingInfo.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(groupPricingInfo.fulfilled, (state, action) => {
            state.loading = 'success';
            state.groupCosts = action.payload.data;
         })
         .addCase(groupPricingInfo.rejected, (state, action) => {
            state.loading = 'failed';
         });
   },
});
export const {
   resetCreated
} = contactSlice.actions;
export default contactSlice.reducer;